import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Вінілові платівки Solarixy
			</title>
			<meta name={"description"} content={"Кожен запис розповідає історію"} />
			<meta property={"og:title"} content={"Головна | Вінілові платівки Solarixy"} />
			<meta property={"og:description"} content={"Кожен запис розповідає історію"} />
			<meta property={"og:image"} content={"https://solarixy.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://solarixy.com/img/3751553.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solarixy.com/img/3751553.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solarixy.com/img/3751553.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solarixy.com/img/3751553.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solarixy.com/img/3751553.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solarixy.com/img/3751553.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" background="#58768c" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 40px 25px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-padding="80px 25px 80px 25px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Link
							href="#"
							color="#e8e4e3"
							margin="0px 0px 25px 0px"
							font="normal 400 16px/1.5 --fontFamily-sans"
							letter-spacing="1px"
							text-decoration-line="initial"
						>
							Вінілові платівки
						</Link>
						<Text
							margin="0px 0px 20px 0px"
							font="normal 400 56px/1.2 --fontFamily-serifGaramond"
							sm-font="normal 700 42px/1.2 --fontFamily-sans"
							color="--light"
							lg-margin="0px 0px 35px 0px"
						>
							Solarixy
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						sm-flex-direction="column"
						align-items="flex-end"
					>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							width="70%"
							lg-width="60%"
							sm-width="100%"
							sm-margin="0px 0px 25px 0px"
							justify-content="flex-end"
						>
							<Text margin="0px 0px 25px 0px" font="--lead" color="#e8e4e3" padding="0px 35px 0px 0px">
							Ми раді, що ви знайшли свій шлях до Solarixy Vinyls, дому для музичних ентузіастів, які плекають тепло і ностальгію за вініловими платівками. Кожен візит до нас - це мандрівка звуковими ландшафтами минулого та сьогодення. Наша затишна, приваблива атмосфера створена для того, щоб ви могли розслабитися, дослідити та відкрити для себе нові музичні скарби.
							</Text>
							<Link href="/contacts" color="#e8e4e3">
								Контакти
							</Link>
						</Box>
					</Box>
				</Box>
				<Image
					src="https://solarixy.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="120px 0 130px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-4">
			<Text
				margin="0px 0px 90px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--primary"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Чому Solarixy?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Ретельно підібрана добірка: 
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Кожна платівка в нашому магазині відібрана з особливою ретельністю, забезпечуючи різноманітність жанрів та епох, щоб задовольнити будь-які музичні смаки.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 34px 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Виняткова якість: 
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Наша прихильність до якості означає, що кожен запис, який ви візьмете додому, гарантовано забезпечить чіткий, насичений звук, яким славиться вініл.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					padding="0px 50px 30px 0"
					sm-padding="0px 0 0 0"
					lg-padding="0px 25px 0 0"
					md-margin="0px 0px 30px 0px"
					sm-margin="0px 0px 20px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0 0 15px 25px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="left"
						>
							Кваліфікований персонал: 
						</Text>
						<Text
							margin="0 0 0 25px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="left"
							border-color="#7a7c7f"
						>
							Незалежно від того, чи ви досвідчений колекціонер, чи допитливий новачок, наша дружня команда тут, щоб провести вас через нашу колекцію і допомогти вам знайти ваш наступний улюблений альбом.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://solarixy.com/img/3.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://solarixy.com/img/4.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Заходьте до нас або пишіть - ми завжди раді спілкуванню з іншими ентузіастами вінілу!
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});